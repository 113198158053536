import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {PlatformThemeService} from "./shared/infrastructure/services/platform-theme.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'REM Plataforma Educativa';
  constructor(
    private platformThemeService: PlatformThemeService

  ) {
  }
}
